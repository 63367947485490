'use client';

import Script from 'next/script';

export default function KMSI() {
    // todo: see if we need this which was causing lint errors: strategy="beforeInteractive"
    return (
        <Script id="kmsi">
            {`const WCS_TIMEOUT = 60 * 60 * 1000; // 60 minutes
        
        const wcsUtils = {
            getCookie: function getCookie(name) {
                var _a;
                const value = '; ' + document.cookie;
                const parts = value.split('; ' + name+'=');
                if (parts.length === 2)
                    return (_a = parts.pop()) === null || _a === void 0 ? void 0 : _a.split(";").shift();
            },
            isWcsSessionExpired: function isWcsSessionExpired() {
                try {
                    const kmsi = wcsUtils.getCookie("kmsi");
                    if (typeof kmsi != "undefined" && kmsi != null && kmsi.indexOf("true") >= 0) {
                        const hashedUserId = wcsUtils.getCookie("hashedUserId");
                        if (!hashedUserId) {
                            return true;
                        }
                        
                        const [ksmiBoolean, ksmiTimestamp] = kmsi.split("_");
                        if (ksmiBoolean && ksmiTimestamp) {
                            const timestamp = parseInt(ksmiTimestamp);
                            return Date.now() - timestamp > WCS_TIMEOUT;
                        }
                    }
                    return false;
                }
                catch (e) {
                    return false;
                }
            },
            isKmsiSession: function isKmsiSession() {
                try {
                    const kmsi = wcsUtils.getCookie("kmsi");
                    if (typeof kmsi === "undefined" || kmsi === null || kmsi === "false") {
                        return false;
                    }
                }
                catch (e) {
                    console.log(e);
                }
                return true;
            },

            kmsi: function kmsi() {
                let ksmiApiCallCount = 0;
                try {
                    if (wcsUtils.isKmsiSession() && wcsUtils.isWcsSessionExpired()) {
                        const xhr = new XMLHttpRequest();
                        xhr.open("GET", "/kmsi", false);
                        xhr.onreadystatechange = function () {
                            if (xhr.readyState === 4) {
                                if (xhr.status === 200) {
                                    try {
                                        const t = JSON.parse(xhr.responseText);
                                        if ("true" === t.kmsi) {
                                            ksmiApiCallCount = 0;
                                        }
                                        else if (ksmiApiCallCount < 3) {
                                            ksmiApiCallCount++;
                                            wcsUtils.kmsi();
                                        }
                                    }
                                    catch (e) {
                                        console.error(e);
                                    }
                                }
                                else {
                                    console.error("Request failed with status: /kmsi ", xhr.status);
                                    if (wcsUtils.isWcsSessionExpired()) {
                                        window.location.href = "/LogonForm?prompt=false&fromPage=/"; // Redirect if conditions are met
                                    }
                                }
                            }
                        };
                        xhr.send();
                    }
                }
                catch (error) {
                    console.log(error);
                }
            },
            isWcsSessionEstablished: function isWcsSessionEstablished() {
                try {
                    const wcsSession = wcsUtils.getCookie("hashedUserId");
                    return !!wcsSession;
                }
                catch (e) {
                    console.log(e);
                }
                return false;
            },
        };
        wcsUtils.kmsi();`}
        </Script>
    );
}
