'use client';

import Script from 'next/script';

import { AirgapAuth } from '@transcend-io/airgap.js-types';

export const CookieMonitoringClient = ({
    airgapSrc,
}: {
    airgapSrc: string;
}) => {
    return (
        <Script
            id="CookieMonitoringScript"
            onLoad={(event: AirgapAuth) => {
                // airgap auth used by TranscendOverride to add overrides after airgap.js load
                window.airgapLoadEvent = event;
            }}
            src={airgapSrc}
        />
    );
};
